<template>
  <div class="container">
    <br />
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ name: 'Home' }">
          Home
        </el-breadcrumb-item>
        <el-breadcrumb-item :to="{ name: 'Grading' }">
          Grading
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          Add Student Grading
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <h3 style="margin-top:10px">{{ $t('grading.Allow a Student to Grade his/her Test')}}</h3>
    <p>
      {{ $t('grading.Add a student test here to allow the student to grade his/her own test')}}
    </p>
    <hr style="border-top: 2px solid #42a16a;" />
    <div class="row form-group">
      <!--student-->
      <label class="col-sm-3" for="username">*  {{ $t('grading.Type')}} </label>
      <div class="col-sm-9">
        <el-radio-group v-model="diagnostic_type" @change="examId = null">
          <el-radio-button label="sat">SAT</el-radio-button>
          <el-radio-button label="act">ACT</el-radio-button>
          <el-radio-button label="toefl">TOEFL</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div class="row form-group">
      <!--test type-->
      <label class="col-sm-3" for="test_type">* {{ $t('grading.Test')}}</label>
      <div class="col-sm-9">
        <el-select
          v-model="examId"
          filterable
          placeholder="Select a test..."
          style="width:100%"
          no-data-text="Loading..."
        >
          <el-option
            v-for="(test, index) in paperList[diagnostic_type]"
            :key="index"
            :value="test.id"
            :label="'[' + test.title + '] ' + test.full_name"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="row form-group" v-show="student_or_section === 'students'">
      <!--student-->
      <label class="col-sm-3" for="username">* {{ $t('grading.Students')}} </label>
      <div class="col-sm-9">
        <el-select
          v-model="student"
          filterable
          multiple
          placeholder="Select a student"
          style="width:100%"
          @change="selectStudent"
          no-data-text="Loading..."
        >
          <el-option
            v-for="(student, index) in students"
            :key="index"
            :value="index"
            :label="
              student.first_name +
                ' ' +
                student.last_name +
                ' (' +
                student.email +
                ')'
            "
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="row form-group" v-show="student_or_section === 'private'">
      <!--Private lessons-->
      <label class="col-sm-3" for="username">* Private lessons </label>
      <div class="col-sm-9">
        <el-select
          v-model="privateLesson"
          filterable
          placeholder="Select a Private lesson"
          style="width:100%"
          @change="selectPrivate"
          no-data-text="Loading..."
        >
          <el-option
            v-for="(lesson, index) in private_lesson"
            :key="index"
            :value="index"
            :label="'[ID:' + lesson.id + '] ' + lesson.title"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="row form-group" v-show="student_or_section === 'sections'">
      <!--student-->
      <label class="col-sm-3" for="username">* Group course</label>
      <div class="col-sm-9">
        <el-select
          v-model="privateLesson"
          filterable
          placeholder="Select a group course"
          style="width:100%"
          @change="selectSection"
          no-data-text="Loading..."
        >
          <el-option
            v-for="(section, index) in group_class"
            :key="index"
            :value="index"
            :label="'[ID:' + section.id + '] ' + section.title"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="row form-group" v-if="selectStudents.length > 0">
      <hr />
      <label class="col-sm-3" for="username">*Students List </label>
      <div class="col-sm-9">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="text-center" style="vertical-align:middle" rowspan="2">
                No
              </th>
              <th
                class="text-center"
                style="vertical-align:middle"
                rowspan="1"
                colspan="2"
              >
                Email
              </th>
              <th class="text-center" style="vertical-align:middle" rowspan="2">
                Action
              </th>
            </tr>
            <tr>
              <th class="text-center">Student</th>
              <th class="text-center">Parent</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(student, index) in selectStudents" :key="index">
              <td class="text-center">{{ index + 1 }}</td>
              <td>
                <p>
                  <a :href="'/user/' + student.user_id"
                    >{{ student.first_name }} {{ student.last_name }}</a
                  >: {{ student.email }}
                </p>
              </td>
              <td class="">
                <p v-for="parent in student.parents" :key="parent.id">
                  <a :href="'/user/' + parent.id"
                    >{{ parent.first_name }} {{ parent.last_name }}:
                  </a>
                  {{ parent.email }}
                </p>
              </td>
              <td class="text-center">
                <el-button
                  type="text"
                  class="text-danger pointer"
                  @click="removeStudent(index)"
                  ><i class="fa fa-trash-alt"></i
                ></el-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <hr />
    <div class="row form-group" v-if="hasClasses.length > 0">
      <label class="col-sm-3" for="username">
        * 这个考卷是不是属于这些班级。 
        <el-tooltip class="item" effect="dark" content="列出包含這個考試的班級" placement="top">
          <el-button type="text"><i class="fas fa-question-circle"></i></el-button>
        </el-tooltip>
      </label>
      <div class="col-sm-9">
         <el-radio-group v-model="isBind">
          <el-radio-button label="1">是</el-radio-button>
          <el-radio-button label="0">否</el-radio-button>
        </el-radio-group>
        <div v-if="isBind === '1'">
          <el-alert title="" type="warning" :closable="false">
            請先添加在班上要考模考的學生（這份考卷會顯示在學生的班級頁面裡），再添加獨立模考的學生。
          </el-alert>
          <div v-for="(student,index) in hasClasses" :key="index" style="margin-top:10px">
            <table class="table table-bordered" >
              <thead>
                <tr>
                  <th>Student</th>
                  <th>Email</th>
                  <th>Classes</th>
                  <th>Test</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(exam,i) in student" :key="i">
                  <td>
                    {{selectStudents[index].first_name +
                      ' ' +
                      selectStudents[index].last_name
                    }}</td>
                  <td>{{ selectStudents[index].email }}</td>
                  <td>{{ exam.title }}</td>
                  <td>Test{{ exam.session_class_assign_tests[0].which_week }}</td>
                </tr>
              </tbody>
            </table>
            <hr />
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <!--student-->
      <label class="col-sm-3" for="username">* {{ $t('grading.Send mail')}} </label>
      <div class="col-sm-9">
        <el-radio-group v-model="sendEmail">
          <el-radio-button label="1">
            {{ $t('grading.Yes') }}
          </el-radio-button>
          <el-radio-button label="0">
            {{ $t('grading.No') }}
          </el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div v-show="sendEmail === '1'">
      <div class="row form-group">
        <!--student-->
        <label class="col-sm-3" for="username">* {{ $t('grading.Email Content')}} </label>
        <div class="col-sm-9">
          <el-radio-group
            v-model="sendTimer"
            v-if="diagnostic_type === 'sat' || diagnostic_type === 'act'"
          >
            <el-radio-button label="1">{{ $t('grading.PDF') }}＋{{ $t('grading.Timer') }}＋{{ $t('grading.Grading Link') }}</el-radio-button>
            <el-radio-button label="2">{{ $t('grading.Timer') }}＋{{ $t('grading.Grading Link') }}</el-radio-button>
            <el-radio-button label="3">{{ $t('grading.Grading Link') }}</el-radio-button>
          </el-radio-group>
          <el-radio-group
            v-model="sendTimer"
            v-if="diagnostic_type === 'toefl'"
          >
            <el-radio-button label="1">Website</el-radio-button>
          </el-radio-group>
        </div>
      </div>
    </div>
    <div v-show="sendEmail === '0' && diagnostic_type === 'toefl' ">
      <!-- <el-radio-group
        v-model="sendTimer"
        v-if="diagnostic_type === 'toefl'"
      >
        <el-radio-button label="1">在線考試網址</el-radio-button>
      </el-radio-group> -->
      <el-alert
        title="Toefl在線考試"
        type="success">
        可以通知學生去 <a href="https://ivy-way.com/toefl">https://ivy-way.com/toefl</a>   頁面進行考試
      </el-alert>
    </div>
    <hr />

    <div>
      <div class="col-xs-4 pull-right" style="margin-bottom:20px">
        <el-button
          type="success"
          style="width:100%;"
          @click="addGrading"
          :disabled="examId === '' || selectStudents.length == 0"
        >
          {{ $t('grading.Confirm') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import enrollmentApi from "@/apis/enrollment";
import Tests from "@/apis/tests";
import role from "@/mixins/role.js";

export default {
  metaInfo() {
    return {
      title: "Grading - Ivy-Way Academy"
    };
  },

  components: {},

  mixins: [role],

  props: [],
  data() {
    return {
      type_arr: ["SAT", "ACT"],
      testType: "SAT",
      selectTests: [],
      selectStudents: [],
      students: [],
      private_lesson: [],
      group_class: [],
      test: "",
      student: "",
      privateLesson: [],
      section: [],
      student_or_section: "students",
      sendEmail: "1",
      sendTimer: "1",
      isBind: "1",
      examId: "",
      paperList: {},
      diagnostic_type: "sat",
      hasClasses: []
    };
  },
  computed: {
  },
  watch: {
    student_or_section(val) {
      if (val === "students") {
        this.student = null;
      }
    },
    diagnostic_type(val) {
      this.sendTimer = 1;
    },
    examId(){
      this.getStudentsClassesTest();
    },
    selectStudents(){
      this.getStudentsClassesTest();
    }
  },

  async mounted() {
    if (this.$route.query.type) {
      this.diagnostic_type = this.$route.query.type;
    }
    await Promise.all([
      enrollmentApi.getAssignTest(),
      Tests.getStudents()
    ]).then(values => {
      this.paperList = values[0];
      this.students = values[1];

      if (this.$route.query.exam_id) {
        this.examId = parseInt(this.$route.query.exam_id);
      }
      if (this.$route.query.student_id) {
        this.students.forEach((item, index) => {
          if (this.$route.query.student_id == item.user_id) {
            this.student.push(index);
            this.selectStudents.push(item);
          }
        });
      }
      this.getStudentsClassesTest();
    });
  },
  methods: {
    async getStudentsClassesTest(){
      if(this.examId > 0 && this.selectStudents.length > 0){
        let user_ids = [];
        let examId = this.examId;
        if (this.selectStudents.length > 0) {
          this.selectStudents.forEach(student => {
            user_ids.push(student.user_id);
          });
        }
        const res = await Tests.getStudentsClassesTest({
          exam_id: examId,
          user_ids: user_ids
        });
        this.hasClasses = [];
        let classes= res["assign-tests"];
        classes.forEach( item => {
          if(item.length > 0){
            this.hasClasses.push(item);
          }
        }); 
      }
    },
    async addGrading() {
      let users = [];
      let examId = this.examId;
      let type = 0;
      if (this.selectStudents.length > 0) {
        this.selectStudents.forEach(student => {
          users.push({
            exam_id: examId,
            user_id: student.user_id
          });
        });
      }
      await Tests.sendTestEmail({
        user_exams: users,
        send_mail: this.sendEmail,
        mail_type: this.sendTimer,
        isBind: this.isBind
      });
      this.selectStudents = [];
      this.student = [];

      this.$message({
        message: "考卷開通成功！",
        type: "success"
      });
    },
    selectSection(index) {
      this.selectStudents = [];
      this.selectStudents = this.group_class[index].students;
    },
    selectPrivate(index) {
      this.selectStudents = [];
      this.selectStudents = this.private_lesson[index].students;
    },
    selectStudent() {
      this.selectStudents = [];
      this.student.forEach(item => {
        console.log(item);
        this.selectStudents.push(this.students[item]);
      });
    },
    removeStudent(index) {
      this.selectStudents.splice(index, 1);
    }
  }
};
</script>

<style scoped>
.select-test {
  width: 100%;
}
.keyboard img {
  width: 100%;
  opacity: 0.2;
}
.keyboard >>> .el-radio__label {
  padding: 0;
}
.keyboard >>> .el-radio__input {
  display: none;
}
img.keyboard_on {
  opacity: 1;
}
</style>
